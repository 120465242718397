import Bead from '../../assets/Bead';
import Color from '../../assets/Color';
import { SAVES_FORMATS } from '../../assets/settings';
import BeadArrayToGridHandler from './BeadArrayToGridHandler';

/**
* Returns a list of Colors currently used with
* usage counter if request
* @param {object[]} beadList
* @param {boolean} count = false
* @returns {Constant.Color[]}
*/
export const getUsedColorList = (beadList, count = false) => {
    let colorList = [];

    beadList.forEach(bead => {
        let colorIndex = -1;
        if (colorList.every((color, index) => {
            colorIndex = index;
            return color.code !== bead.color.code; 
        })) {
            let colorCopy = Object.assign(count ? {count: 1} : {}, bead.color);
            colorList.push(colorCopy);
        } else if (count) {
            colorList[colorIndex].count++;
        }
    });
    
    return colorList;
};

/**
 * Returns the index of a color in a list of colors by comparing color codes
 * @param {Color[]} colorList
 * @param {string} colorCode
 * @returns {number}
 */
export const getBeadIndexByColorCode = (colorList, colorCode) => {
    let currentIndex = -1;

    const test = (color, index) => {
        currentIndex = index;
        return color.code !== colorCode;
    };
    
    return !colorList.every(test) ? currentIndex : -1;
};

/**
 * Reduces Color objects in a list for saving purposes
 * @param {Color[]} colorList 
 */
const minifyColorList = (colorList) => {
    return colorList.map(color => ([
            color.code,
            color.name,
            color.type
    ]));
}

/**
 * Reduces the list of beads to minify save file's size
 * @param {object[]} beadList
 * @param {object} gridSize
 * @returns {object}
 */
export const minifyBeadList = (beadList, gridSize) => {
    let currentColorIndex = -1;
    let currentGroup = null;
    let toReturn = [
        getUsedColorList(beadList),
        [],
        [ gridSize.columns, gridSize.rows ]
    ];

    beadList.forEach(bead => {
        const { color } = bead;
        const colorIndex = getBeadIndexByColorCode(toReturn[0], color.code);
        
        if (colorIndex === -1) {
            throw ErrorEvent;
        }

        if (colorIndex !== currentColorIndex){
            if (currentGroup){
                toReturn[1].push(currentGroup);
            }
            // cI = colorIndex, c = count
            currentGroup = [colorIndex, 1];
            currentColorIndex = colorIndex;
            return;
        }

        currentGroup[1]++;
    });

    toReturn[1].push(currentGroup);
    toReturn[0] = minifyColorList(toReturn[0]);

    return toReturn;
};

/**
 * Expands the minified bead list app usable bead list
 * Supports all versions of the save files formats
 * @param {object} saveContent
 */
export const expandSaveContent = (saveContent) => {
    const formatNumber = getSaveVersion(saveContent);
    let beadList = [];

    switch (formatNumber) {
        case SAVES_FORMATS.V1:{
            return { 
                beadList:   saveContent.beadList,
                gridSize:   saveContent.gridSize
            };
        }
            
        case SAVES_FORMATS.V2:{
            saveContent.beadGroups.forEach(beadGroup => {
                for (let i = 0; i < beadGroup.count; i++) {
                    beadList.push(new Bead(beadGroup.color));
                }
            });
            
            return { 
                beadList,
                gridSize:   saveContent.gridSize
            };
        }
            
        case SAVES_FORMATS.V3: {
            const { colorList, beadGroups } = saveContent;

            beadGroups.forEach(beadGroup => {
                for (let i = 0; i < beadGroup.count; i++) {
                    let colorCopy = Object.assign({}, colorList[beadGroup.colorIndex]);
                    
                    // Cleaning some previous unnecessary saved fields
                    if (colorCopy.count) {
                        delete colorCopy.count;
                    }

                    beadList.push(new Bead(colorCopy));
                }
            });

            return { 
                beadList,
                gridSize:   saveContent.gridSize
            };
        }

        case SAVES_FORMATS.V4: {
            const { colorList, beadGroups } = saveContent;

            beadGroups.forEach(beadGroup => {
                for (let i = 0; i < beadGroup.c; i++) {
                    const color = colorList[beadGroup.cI];
                    beadList.push(new Bead(new Color(color.c, color.n, color.t)));
                }
            });
            
            return { 
                beadList,
                gridSize:   saveContent.gridSize
            };
        }

        case SAVES_FORMATS.V5: {
            const [colorList, beadGroups, gridSize] = saveContent;

            beadGroups.forEach(beadGroup => {
                for (let i = 0; i < beadGroup[1]; i++) {
                    const color = colorList[beadGroup[0]];
                    beadList.push(new Bead(new Color(color[0], color[1], color[2])));
                }
            });
            
            return { 
                beadList,
                gridSize:   { columns: gridSize[0], rows: gridSize[1] }
            };
        }

        default:
            throw ErrorEvent;
    }
};

/**
 * Returns the version of a saved template content
 * @param {*} saveContent 
 */
export const getSaveVersion = saveContent => {
    /**
     * V5 is the first save to be an array. Save version is at position 3
     * V2 to V4 were objects that had a 'saveVersion' param
     */
    return Array.isArray(saveContent)
        ? saveContent[3]
        : saveContent.saveVersion
            ? saveContent.saveVersion
            : SAVES_FORMATS.V1;
}

/**
 * Returns a list of bead indexes to select using
 * a list of beads and a chosen bead index
 * @param {object[]} beadList
 * @param {object}
 */
export const getAutoAreaSelection = (beadList, { index, columns }) => {
    const selectedBeadCoord = {
        x: Math.floor(index / columns),
        y: index % columns
    };

    // recursiveNeighborSelection uses a grid of colors, comparing color codes
    return (new BeadArrayToGridHandler(beadList, columns)).getRecursiveNeighborSelection(selectedBeadCoord);
};

/**
 * Deep clones an array of objects
 * @param {object[]} items 
 */
export const objectClone = (items) => items.map(item => {
    switch (typeof item) {
        case 'array':
            return objectClone(item);
        case 'object':
            return Object.assign({}, item);
        default:
            return item;
    }
});