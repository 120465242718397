import * as TYPES from '../actions/types';
import { 
    DEFAULT_BEAD,
    HEADER_TYPES,
    EMPTY_COLOR
} from '../assets/settings';

import { getAutoAreaSelection, objectClone } from '../components/_helpers';

/**
 * BEADLIST reducer
 * @param {object[]} state 
 * @param {object} action 
 */
export default (state = [], { payload, type }) => {
    const {
        beadList,
        color,
        columns, 
        direction,
        gridSizes,
        index,
        position
    } = payload ? payload : {};
    const { headerType, x, y } = position ? position : {};

    // To be returned
    let newBeads = [];
    // To avoid modifying state
    const stateCopy = objectClone(state);

    switch (type) {
        // Give a selected color to all beads in an enclaved area
        case TYPES.APPLY_TO_BEAD_AREA_TYPE: {
            const autoSelection = getAutoAreaSelection(stateCopy, { index, columns });

            // set empty color if all beads have the same color as selected color
            // otherwise give beads selected color
            const appliedColor = autoSelection.every(beadIndex => stateCopy[beadIndex].color.code === color.code) 
                ? { ...EMPTY_COLOR } 
                : { ...color };
            
            autoSelection.forEach(beadIndex => {
                stateCopy[beadIndex] = { ...stateCopy[beadIndex], color: appliedColor }
            })
            
            return stateCopy;
        }

        // Give a selected color to a clicked bead
        case TYPES.APPLY_TO_SINGLE_BEAD_TYPE: {
            // set empty color if bead has the same color as selected color
            // otherwise give bead selected color
            const appliedColor = stateCopy[index].color.code === color.code ? { ...EMPTY_COLOR } : { ...color };
            stateCopy[index] = { ...stateCopy[index], color: appliedColor };
            return stateCopy;
        }

        // Set passed bead list as current list
        case TYPES.UPDATE_BEAD_LIST_TYPE: {
            return beadList;
        }

        // Apply a color to all beads of the same color as the clicked on
        case TYPES.APPLY_TO_BEADS_OF_SAME_COLOR_TYPE:{
            const clickedBeadColorCode = stateCopy[index].color.code;

            return stateCopy.map(bead => 
                bead.color.code === clickedBeadColorCode
                    ? { ...bead, color }
                    : { ...bead }
            );
        }

        // Update the number of columns
        case TYPES.UPDATE_COLUMNS_TYPE: {
            const prevColumns = gridSizes.prevGridSize.columns;
            const newColumns = gridSizes.newGridSize.columns;
            const newRows = gridSizes.newGridSize.rows;
            // hop and hopTracker make sure we keep existing beads or create new ones accordingly to the new row count
            const hop = prevColumns !== newColumns ? prevColumns - newColumns : 0;
            let hopTracker = 0;
            
            for (let row = 0; row < newRows; row++) {
                for (let i = 0; i < newColumns; i++) {
                    const index = row * newColumns + i + hopTracker;
                    // if beyond previous column count, create new beads
                    if (i >= prevColumns) {
                        newBeads.push(DEFAULT_BEAD);
                    } else {
                        newBeads.push(typeof stateCopy[index] !== 'undefined' 
                            ? { ...stateCopy[index] } 
                            : DEFAULT_BEAD);
                    }
                }
                hopTracker += hop;
            }

            return newBeads;
        }

        // Flip the grid vertically
        case TYPES.FLIP_GRID_TYPE: {
            switch (direction){
                case 'horizontal':
                default:
                    const rows = stateCopy.length / columns;
                    for (let i = 0; i < rows; i++) {
                        for(let j = (columns * (i + 1) - 1); j >= columns * i; j--) {
                            newBeads.push({...stateCopy[j]});
                        }
                    }
            }
            return newBeads;
        }

        // Add a line from a selected header
        case TYPES.ADD_LINE_TYPE:
            switch (headerType){
                case HEADER_TYPES.COLUMN: {
                    const rows = stateCopy.length / columns;
                    let index = direction === 'after' ? x : x - 1;
                    for (let i = 0; i < rows; i++) {
                        stateCopy.splice(index,
                            0,
                            DEFAULT_BEAD);
                        index += columns + 1;
                    }
                    break;
                }

                case HEADER_TYPES.ROW: {
                    const newLine = direction === 'after' ? y : y - 1;
                    const initialIndex = columns * newLine;
                    for (let index = 0; index < columns; index++) {
                        stateCopy.splice(initialIndex,
                            0,
                            DEFAULT_BEAD);
                    }
                    break;
                }

                default:
                    break;
            }
            return stateCopy;
            
        // Delete a line from a selected header
        case TYPES.DELETE_LINE_TYPE:
            switch (headerType){
                case HEADER_TYPES.COLUMN: {
                    const initialLength = stateCopy.length;
                    for (let index = x - 1; index <= initialLength; index += columns - 1) {
                        stateCopy.splice(index, 1);
                    }
                    break;
                }

                case HEADER_TYPES.ROW: {
                    const initialIndex = columns * (y - 1);
                    stateCopy.splice(initialIndex, columns);
                    break;
                }

                default:
                    break;
            }
            return stateCopy;

        // Update the number of rows
        case TYPES.UPDATE_ROWS_TYPE: {
            const { columns, rows } = gridSizes.newGridSize;
            for (let row = 0; row < rows; row++) {
                for (let i = 0; i < columns; i++) {
                    const index = row * columns + i;
                    newBeads.push(
                        typeof stateCopy[index] !== 'undefined' 
                            ? { ...stateCopy[index] } 
                            : DEFAULT_BEAD
                    );
                }
            }
    
            return newBeads;
        }

        default:
            return stateCopy;
    }
};