export default class BeadArrayToGridHandler {
    #sourceArray = [];
    #width = 0;
    #height = 0;
    #colorsGrid = [];
    #selectedList = [];
    #recursiveTestOrder = [ 'UP', 'LEFT', 'DOWN', 'RIGHT'];
    constructor(sourceArray, width) {
        this.#sourceArray = sourceArray;
        this.#width = width;
        this.beadArrayToGrid();
    }

    beadArrayToGrid() {
        let x = 0;
        let y = 0;

        this.#colorsGrid = [];
        this.#colorsGrid.push([]);
        this.#sourceArray.forEach((bead) => {
            if (y >= this.#width) {
                y = 0;
                x++;
                this.#colorsGrid.push([]);
            }

            this.#colorsGrid[x].push(bead.color.code);

            y++;
        });
        this.#height = this.#colorsGrid.length;
    }

    getRecursiveNeighborSelection(coordinates) {
        this.#selectedList = [];
        return this.recursiveNeighborSelection(coordinates);
    }

    recursiveNeighborSelection = (coordinates) => {
        this.#selectedList.push(coordinates.x * this.#width + coordinates.y);
        for (let i = 0; i < this.#recursiveTestOrder.length; i++) {
            let x = -1;
            let y = -1;

            switch ( this.#recursiveTestOrder[i] ){
                case 'UP':
                    x = coordinates.x - 1;
                    y = coordinates.y;
                    break;
                
                case 'LEFT':
                    x = coordinates.x;
                    y = coordinates.y - 1;
                    break;
    
                case 'DOWN':
                    x = coordinates.x + 1;
                    y = coordinates.y;
                    break;
    
                case 'RIGHT':
                default:
                    x = coordinates.x;
                    y = coordinates.y + 1;
            };
            
            if ( (y >= 0 && y < this.#width) 
                && (x >= 0 && x < this.#height) 
                && this.#colorsGrid[x][y] === this.#colorsGrid[coordinates.x][coordinates.y]
                && this.#selectedList.indexOf(x * this.#width + y) === -1
            ) {
                this.#selectedList = this.recursiveNeighborSelection({x, y});
            }
        }
    
        return this.#selectedList;
    }
};