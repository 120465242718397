import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WaitModal from './_generic/WaitModal';
import { 
    clearHistoryAction,
    updateBeadListAction, 
    updateGridSizeAction
} from '../actions';
import { DEFAULT_BEAD_SET, MIN_GRID_DIMENSIONS } from '../assets/settings';

import './_style/App.css';

const Edition = lazy(() => import('./Edition'));
const Gallery = lazy(() => import('./Gallery'));
const TemplateView = lazy(() => import('./Gallery'));

const App = ({
    clearHistoryAction,
    updateBeadListAction,
    updateGridSizeAction,
    visibleWaitAnimation
}) => {     
    /**
     * Initialize the grid
     */
    useEffect(() => {
        const init = async () => {
            updateGridSizeAction({ columns: MIN_GRID_DIMENSIONS.COLUMNS, rows: MIN_GRID_DIMENSIONS.ROWS });
            await updateBeadListAction(DEFAULT_BEAD_SET);
            clearHistoryAction();
        }

        init();
    }, [updateGridSizeAction, updateBeadListAction, clearHistoryAction]);
    
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <div className="ui app-container">
                    <WaitModal open={ visibleWaitAnimation } />
                    <Route path='/' exact component={ Edition } />
                    <Route path='/gallery' exact component={ Gallery } />
                    <Route path='/gallery/view' exact component={ TemplateView } />
                </div>
            </Suspense>
        </BrowserRouter>
    );
}

process.env.NODE_ENV !== 'production' ? App.propTypes = {
    clearHistoryAction:           PropTypes.func.isRequired,
    updateBeadListAction:         PropTypes.func.isRequired,
    updateGridSizeAction:         PropTypes.func.isRequired,
    visibleWaitAnimation:   PropTypes.bool
} : void 0;

const mapStateToProps = state => {
    return { 
        visibleWaitAnimation:   state.unTrackedUserParams.visibleWaitAnimation
    };
}

export default connect(mapStateToProps, { 
    clearHistoryAction,
    updateBeadListAction,
    updateGridSizeAction
})(App);