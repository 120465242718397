import { EMPTY_COLOR } from './constants';

export default class Color{
    code;
    name;
    type;
    
    /**
     * @param {string} givenCode 
     * @param {string} givenName 
     * @param {string} givenType 
     */
    constructor(givenCode = EMPTY_COLOR.code, givenName = EMPTY_COLOR.name, givenType = EMPTY_COLOR.type) {
        this.code = givenCode;
        this.name = givenName;
        this.type = givenType;
    }
};