export const ADD_LINE_TYPE                      = 'ADD_LINE_TYPE';
export const APPLY_COLOR_TYPE                   = 'APPLY_COLOR_TYPE';
export const APPLY_TO_BEAD_AREA_TYPE            = 'APPLY_TO_BEAD_AREA_TYPE';
export const APPLY_TO_BEADS_OF_SAME_COLOR_TYPE  = 'APPLY_TO_BEADS_OF_SAME_COLOR_TYPE';
export const APPLY_TO_SINGLE_BEAD_TYPE          = 'APPLY_TO_SINGLE_BEAD_TYPE';
export const CLEAR_HISTORY_TYPE                 = 'CLEAR_HISTORY_TYPE';
export const DELETE_LINE_TYPE                   = 'DELETE_LINE_TYPE';
export const DISPLAY_COLOR_PICKER_TYPE          = 'DISPLAY_COLOR_PICKER_TYPE';
export const DISPLAY_DEFAULT_COLOR_TYPE         = 'DISPLAY_DEFAULT_COLOR_TYPE';
export const DISPLAY_GRID_HEADERS_TYPE          = 'DISPLAY_GRID_HEADERS_TYPE';
export const DISPLAY_WAIT_ANIMATION_TYPE        = 'DISPLAY_WAIT_ANIMATION_TYPE';
export const ENABLE_HOTKEYS_TYPE                = 'ENABLE_HOTKEYS_TYPE';
export const FLIP_GRID_TYPE                     = 'FLIP_GRID_TYPE';
export const SAVE_CURRENT_STATE_TYPE            = 'SAVE_CURRENT_STATE_TYPE';
export const SELECT_COLOR_TYPE                  = 'SELECT_COLOR_TYPE';
export const SELECT_GRID_HEADER_TYPE            = 'SELECT_GRID_HEADER_TYPE';
export const UPDATE_BEAD_LIST_TYPE              = 'UPDATE_BEAD_LIST_TYPE';
export const UPDATE_BEAD_STYLE_TYPE             = 'UPDATE_BEAD_STYLE_TYPE';
export const UPDATE_COLUMNS_TYPE                = 'UPDATE_COLUMNS_TYPE';
export const UPDATE_GRID_SIZE_TYPE              = 'UPDATE_GRID_SIZE_TYPE';
export const UPDATE_ROWS_TYPE                   = 'UPDATE_ROWS_TYPE';
export const UPDATE_SELECTION_TYPE_TYPE         = 'UPDATE_SELECTION_TYPE_TYPE';
export const ZOOM_CANVAS_TYPE                   = 'ZOOM_CANVAS_TYPE';