import * as TYPES from './types';
import { SELECTION_TYPES } from "../assets/constants";

/***********************************************
 * Not exported actions
 * ********************************************/

/**
 * Wrapper for actions to trigger wait animation 
 * @param {*} value 
 * @param {func} action 
 */
const waitDisplayAction = (value, action) => async (dispatch, getState) => {
    // force async behaviour to display the waiting animation
    await new Promise((resolve) => {
        dispatch(displayWaitAnimationAction(true));
        setTimeout(() => resolve(), 0);
    });
    // trigger the action
    dispatch(action(value, getState()));
    // remove wait
    dispatch(displayWaitAnimationAction(false));
};

const applyToBeadAreaAction = (index, color) => (dispatch, getState) => {
    const state = getState();

    dispatch({
        type: TYPES.APPLY_TO_BEAD_AREA_TYPE,
        payload: {
            index,
            columns: state.trackedUserParams.present.gridSize.columns,
            color
        }
    });
};

const applyToBeadsOfSameColorAction = (index, color) => {
    return { type: TYPES.APPLY_TO_BEADS_OF_SAME_COLOR_TYPE, payload: { index, color } }
};

const applyToSingleBeadAction = (index, color) => {
    return { type: TYPES.APPLY_TO_SINGLE_BEAD_TYPE, payload: { index, color } }
};

/***********************************************
 * *********************************************
 * ********************************************/



/***********************************************
 * Exported actions
 * ********************************************/


export const applyColorAction = color => {
    return { type: TYPES.APPLY_COLOR_TYPE, payload: { color } };
};

export const addLineAction = direction => waitDisplayAction(direction, (direction, state) => {
    return {
        type: TYPES.ADD_LINE_TYPE,
        payload: { 
            direction,
            position: state.trackedUserParams.present.selectedHeaderPosition,
            ...state.trackedUserParams.present.gridSize
        }
    };
});

export const clearHistoryAction = () => {
    return { type: TYPES.CLEAR_HISTORY_TYPE };
}

export const deleteLineAction = () => waitDisplayAction(null, (_, state) => {
    return {
        type: TYPES.DELETE_LINE_TYPE,
        payload: { 
            position: state.trackedUserParams.present.selectedHeaderPosition, 
            ...state.trackedUserParams.present.gridSize
        }
    };
});

export const displayColorPickerAction = isChecked => {
    return { type: TYPES.DISPLAY_COLOR_PICKER_TYPE, payload: { isChecked } };
}

export const displayDefaultColorAction = isChecked => {
    return { type: TYPES.DISPLAY_DEFAULT_COLOR_TYPE, payload: { isChecked } };
}

export const displayGridHeadersAction = isChecked => {
    return { type: TYPES.DISPLAY_GRID_HEADERS_TYPE, payload: { isChecked } };
}

export const displayWaitAnimationAction = isDisplayed => {
    return { type: TYPES.DISPLAY_WAIT_ANIMATION_TYPE, payload: { isDisplayed } };
}

export const enableHotkeysAction = isHotkeyEnabled => {
    return { type: TYPES.ENABLE_HOTKEYS_TYPE, payload: { isHotkeyEnabled } }
}

export const flipGridAction = (direction='horizontal') => waitDisplayAction(direction, (direction, state) => {
    return {
        type: TYPES.FLIP_GRID_TYPE,
        payload: { 
            direction,
            ...state.trackedUserParams.present.gridSize
        }
    };
});

export const saveCurrentStateAction = () => {
    return { type: TYPES.SAVE_CURRENT_STATE_TYPE };
}

export const selectBeadAction = index => (dispatch, getState) => {
    const { selectedColor, selectionType } = getState().unTrackedUserParams;
    
    switch (selectionType) {
        case SELECTION_TYPES.COLOR_AREA:
            dispatch(applyToBeadAreaAction(index, selectedColor));
            break;
        case SELECTION_TYPES.BEAD_COLOR:
            dispatch(applyToBeadsOfSameColorAction(index, selectedColor));
            break;
        case SELECTION_TYPES.SINGLE:
        default:
            dispatch(applyToSingleBeadAction(index, selectedColor));
            break;
    }
};

export const selectColorAction = color => {
    return { type: TYPES.SELECT_COLOR_TYPE, payload: { color } };
};

export const selectGridHeaderAction = position => {
    return { type: TYPES.SELECT_GRID_HEADER_TYPE, payload: { position } }
}

export const updateBeadListAction = beadList => waitDisplayAction(beadList, beadList  => {
    return { type: TYPES.UPDATE_BEAD_LIST_TYPE, payload: { beadList } };
});

export const updateBeadStyleAction = style => {
    return { type: TYPES.UPDATE_BEAD_STYLE_TYPE, payload: { style } }
};

export const updateColumnsAction = number => waitDisplayAction(number, (number, state) => {
    return {
        type: TYPES.UPDATE_COLUMNS_TYPE,
        payload: { 
            gridSizes: {
                newGridSize: {
                    columns:    number,
                    rows:       state.trackedUserParams.present.gridSize.rows,
                },
                prevGridSize: state.trackedUserParams.present.gridSize,
            },
            number
        }
    };
});

export const updateGridSizeAction = gridSize => waitDisplayAction(gridSize, gridSize => {
    return { type: TYPES.UPDATE_GRID_SIZE_TYPE, payload: { gridSize } };
});

export const updateRowsAction = number => waitDisplayAction(number, (number, state) => {
    return {
        type: TYPES.UPDATE_ROWS_TYPE,
        payload: { 
            gridSizes: {
                newGridSize: {
                    columns:    state.trackedUserParams.present.gridSize.columns,
                    rows:       number,
                },
                prevGridSize: state.trackedUserParams.present.gridSize
            },
            number
        }
    };
});

export const updateSelectionTypeAction = beadType => {
    return { type: TYPES.UPDATE_SELECTION_TYPE_TYPE, payload: { beadType } }
};

export const zoomCanvasAction = zoomType => {
    return { type: TYPES.ZOOM_CANVAS_TYPE, payload: { zoomType } }
};

/***********************************************
 * *********************************************
 * ********************************************/