import Color from './Color';

/**
 * actions/index.js
 * assets/settings.js
 * components/Color/ColorPicker.js
 * components/Dashboard/Dashboard.js
 * components/Grid/Bead.js
 * reducers/beadListReducer.js
 * @type {object}
 */
export const BEAD_STYLES = {
    BEAD:       'bead',
    CHECKERING: 'checkering',
    FUSED:      'fused',
    MELTED:     'melted'
};

/**
 * assets/settings.js
 * components/Dashboard/Dashboard.js
 * @type {object}
 */
export const SELECTION_TYPES = {
    SINGLE: 'single',
    COLOR_AREA: 'color-area',
    BEAD_COLOR: 'bead-color'
};

/**
 * assets/settings.js
 * components/App.js
 * components/Dashboard/Dashboard.js
 * @type {object}
 */
export const MIN_GRID_DIMENSIONS = { ROWS: 4, COLUMNS: 4 }

/**
 * components/Dashboard/Dashboard.js
 * @type {object}
 */
export const MAX_GRID_DIMENSIONS = { ROWS: 150, COLUMNS: 150 };

/**
 * components/Dashboard/Dashboard.js
 * reducers/undoable.js
 * @type {number}
 */
export const MAX_UNDO_REDO_STACK = 10;

/**
 * assets/Color.js
 * components/Grid/Bead.js
 */
export const COLOR_TYPES = {
    DEFAULT:        'Default',
    GLITTER:        'Glitter',
    GLOW:           'Glow',
    METALLIC:       'Metallic',
    NEON:           'Neon',
    PEARL:          'Pearl',
    STANDARD:       'Standard',
    TRANSLUCENT:    'Translucent',
    VARIANTS:       'Variants'
};

/**
 * assets/Bead.js
 * components/Color/ColorPicker.js
 * @type {Color}
 */
export const EMPTY_COLOR = new Color('#FFFFFF', 'Empty', COLOR_TYPES.DEFAULT);

/**
 * Components/Dashboard/Dashboard.js
 * reducers/undoable.js
 * @type {Color[]}
 */
export const COLOR_LIST = [
    EMPTY_COLOR,
    new Color('#F7F7F2', 'White'),
    new Color('#BEC3BF', 'Lgt Gray'),
    new Color('#96989C', 'Gray'),
    new Color('#93A19F', 'Pewter'),
    new Color('#545F5F', 'Charcoal'),
    new Color('#56575C', 'Drk Gray'),
    new Color('#343234', 'Black'),
    new Color('#F1E5D8', 'Toast. Marsh.'),
    new Color('#EAC49F', 'Sand'),
    new Color('#D7B087', 'Fawn'),
    new Color('#CFA889', 'Tan'),
    new Color('#A04E3F', 'Rust'),
    new Color('#88404F', 'Cranapple'),
    new Color('#9D7153', 'Lgt Brown'),
    new Color('#6C524D', 'Brown'),
    new Color('#EDE7BA', 'Cream'),
    new Color('#FAEE8D', 'Past. Yellow'),
    new Color('#F9D737', 'Yellow'),
    new Color('#FFB64E', 'Cheddar'),
    new Color('#FF803E', 'Orange'),
    new Color('#E19A52', 'Butterscotch'),
    new Color('#FF6158', 'Hot Coral'),
    new Color('#FF777F', 'Salmon'),
    new Color('#FF9E8D', 'Blush'),
    new Color('#FFB5BE', 'Flamingo'),
    new Color('#FCC6B8', 'Peach'),
    new Color('#F5C0D5', 'Lgt Pink'),
    new Color('#E16D9D', 'Bubble Gum'),
    new Color('#E65794', 'Pink'),
    new Color('#C43A44', 'Red'),
    new Color('#AD3345', 'Cherry'),
    new Color('#AD3C6C', 'Raspberry'),
    new Color('#B25FAA', 'Plum'),
    new Color('#B4A6D3', 'Lgt Lavender'),
    new Color('#9582BB', 'Past. Lavender'),
    new Color('#6F5493', 'Purple'),
    new Color('#87A7E1', 'Blueberry crème'),
    new Color('#6C88BF', 'Periwinkle'),
    new Color('#B4D9DF', 'Robin\'s Egg'),
    new Color('#63A9D6', 'Past. Blue'),
    new Color('#278ACB', 'Lgt Blue'),
    new Color('#0066B3', 'Cobalt'),
    new Color('#2B307C', 'Drk Blue'),
    new Color('#162846', 'Midnight'),
    new Color('#B0E8D5', 'Toothpaste'),
    new Color('#008FCC', 'Turquoise'),
    new Color('#38C7AF', 'Lgt Green'),
    new Color('#00968A', 'Parrot Green'),
    new Color('#73D594', 'Pastel Green'),
    new Color('#77CA4A', 'Kiwi Lime'),
    new Color('#54B160', 'Bright Green'),
    new Color('#009654', 'Shamrock'),
    new Color('#108355', 'Drk Green'),
    new Color('#CBD735', 'Prickly Pear'),
    new Color('#3C614F', 'Evergreen'),
    new Color('#00AC4A', 'N. Green', COLOR_TYPES.NEON),
    new Color('#BDD200', 'N. Yellow', COLOR_TYPES.NEON),
    new Color('#F15289', 'N. Pink', COLOR_TYPES.NEON),
    new Color('#FF8D2E', 'N. Orange', COLOR_TYPES.NEON),
    new Color('#2255B7', 'N. Blue', COLOR_TYPES.NEON),
    new Color('#777B81', 'M. Silver', COLOR_TYPES.METALLIC),
    new Color('#B57F45', 'M. Gold', COLOR_TYPES.METALLIC),
    new Color('#97634E', 'M. Copper', COLOR_TYPES.METALLIC),
    new Color('#D8D6C7', 'Pearl', COLOR_TYPES.PEARL),
    new Color('#e37bad', 'P. Pink', COLOR_TYPES.PEARL),
    new Color('#CCA49B', 'P. Light Pink', COLOR_TYPES.PEARL),
    new Color('#E99591', 'P. Coral', COLOR_TYPES.PEARL),
    new Color('#D1C66E', 'P. Yellow', COLOR_TYPES.PEARL),
    new Color('#84B095', 'P. Green', COLOR_TYPES.PEARL),
    new Color('#7AAEA2', 'P. Light Blue', COLOR_TYPES.PEARL),
    new Color('#9D382E', 'G. Red', COLOR_TYPES.GLITTER),
    new Color('#0069C0', 'G. Blue', COLOR_TYPES.GLITTER),
    new Color('#694184', 'G. Purple', COLOR_TYPES.GLITTER),
    new Color('#BAB456', 'G. Yellow', COLOR_TYPES.GLITTER),
    new Color('#00876E', 'G. Green', COLOR_TYPES.GLITTER),
    new Color('#A6A9B1', 'G. Clear', COLOR_TYPES.GLITTER),
    new Color('#383737', 'G. Black', COLOR_TYPES.GLITTER),
    new Color('#D0DBDE', 'G. White', COLOR_TYPES.GLITTER),
    new Color('#E2587F', 'G. Pink', COLOR_TYPES.GLITTER),
    new Color('#9BAF9E', 'G. Fairy Dust', COLOR_TYPES.GLITTER),
    new Color('#559E45', 'G. Kiwi', COLOR_TYPES.GLITTER),
    new Color('#EEEEEE', 'T. Transparent', COLOR_TYPES.TRANSLUCENT),
    new Color('#9EA7AE', 'T. Clear', COLOR_TYPES.TRANSLUCENT),
    new Color('#7CD2F2', 'T. Blue', COLOR_TYPES.TRANSLUCENT),
    new Color('#006d8b', 'T. Turquoise', COLOR_TYPES.TRANSLUCENT),
    new Color('#AFC39D', 'Glow Green', COLOR_TYPES.GLOW),
    new Color('#56ACBE', 'Glow Blue', COLOR_TYPES.GLOW),
    new Color('#EE75A8', 'Glow Pink', COLOR_TYPES.GLOW),
    new Color('#AE66B1', 'Glow Purple', COLOR_TYPES.GLOW),
    new Color('#EAAF6F', 'Glow Orange', COLOR_TYPES.GLOW),
    new Color('#649DCF', 'V. Blue', COLOR_TYPES.VARIANTS),
    new Color('#9D74C4', 'V. Purple', COLOR_TYPES.VARIANTS),
    new Color('#CD699E', 'V. Pink', COLOR_TYPES.VARIANTS),
    new Color('#C86069', 'V. Red', COLOR_TYPES.VARIANTS),
    new Color('#55AF7E', 'V. Green', COLOR_TYPES.VARIANTS),
    new Color('#384E48', 'V. Dark Green', COLOR_TYPES.VARIANTS),
    new Color('#494D4D', 'V. Black', COLOR_TYPES.VARIANTS),
];

/**
 * components/_helpers/index.js
 * components/FileManagement/TemplateSaver.js
 * components/FileManagement/TemplateUploader.js
 * @type {object}
 */
export const SAVES_FORMATS = {
    V1: 0,
    V2: 1,
    V3: 2,
    V4: 3,
    V5: 4
}

/**
 * components/Dashboard/Dashboard.js
 */
export const VERSION_LABEL = 'v1.14.1.0';

export const HEADER_TYPES = {
    COLUMN: 'column-header',
    ROW:    'row-header'
}