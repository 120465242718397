import Bead from './Bead';
import * as CONSTANTS from './constants';
export * from './constants';

const {
    BEAD_STYLES,
    EMPTY_COLOR,
    MIN_GRID_DIMENSIONS,
    SELECTION_TYPES
} = CONSTANTS;

/**
 * components/Grid/Bead.js
 * reducers/beadListReducer.js
 * @type {Bead}
 */
export const DEFAULT_BEAD = new Bead(EMPTY_COLOR);

/**
 * components/App.js
 * @type {Object[]}
 */
export const DEFAULT_BEAD_SET = Array(MIN_GRID_DIMENSIONS.COLUMNS * MIN_GRID_DIMENSIONS.ROWS)
    .fill()    
    .map(() => DEFAULT_BEAD);

/**
 * components/Dashboard/Dashboard.js
 * @type {object}
 */
export const DEFAULT_CANVAS_SIZE = { SIZE: 40, MIN: 10, MAX: 100 };

/**
 * components/Color/ColorItem.js
 * components/Dashboard/EmptyMenuItem.js
 * components/Dashboard/IconCheckbox.js
 * @type {object}
 */
export const DEFAULT_THEME = {
    ACTIVE_ICON_COLOR:      'blue',
    BACKGROUND_COLOR:       '#FFFFFF',
    BEAD_SELECTION_COLOR:   'grey',
    PRIMARY_COLOR:          '#FFFFFF',
    INACTIVE_ICON_COLOR:    'grey',
    SECONDARY_COLOR:        '#F7F7F7',
    TEXT_COLOR:             '#000000'
}

/**
 * reducers/trackedUserParamsReducer.ls
 * @type {object}
 */
export const DEFAULT_TRACKED_USER_PARAMS = {
    gridSize:               MIN_GRID_DIMENSIONS,
    selectedHeaderPosition: {}
};

/**
 * reducers/trackedUserParamsReducer.ls
 * @type {object}
 */
export const DEFAULT_ZOOMING_STEP = 5;

/**
 * reducers/unTrackedUserParamsReducer.ls
 * @type {object}
 */
export const DEFAULT_UNTRACKED_USER_PARAMS = {
    beadStyle:              BEAD_STYLES.BEAD,
    canvasSize:             DEFAULT_CANVAS_SIZE.SIZE,
    isHotkeyEnabled:        true,
    selectedColor:          EMPTY_COLOR,
    selectionType:          SELECTION_TYPES.SINGLE,
    visibleDefaultColor:    true,
    visibleGridHeaders:     false,
    visibleSidebars:        true,
    visibleWaitAnimation:   false
};

/**
 * components/Gallery.js
 * @type {number}
 */
export const MAX_TEMPLATES_DISPLAY = 20;