import { combineReducers } from "redux";
import undoable from './undoable';
import beadListReducer from './beadListReducer';
import trackedUserParamsReducer from './trackedUserParamsReducer';
import unTrackedUserParamsReducer from './unTrackedUserParamsReducer';

const combinedReducers = combineReducers({
    // Tracks the list/grid of beads
    beadList: undoable(beadListReducer),
    // Tracks the user options we want tracked by the undo/redo feature
    trackedUserParams: undoable(trackedUserParamsReducer),
    // Tracks the user options that don't need part of the undo/redo feature
    unTrackedUserParams: unTrackedUserParamsReducer
});

export default combinedReducers;