import { DEFAULT_CANVAS_SIZE, DEFAULT_ZOOMING_STEP } from '../assets/settings';
import * as TYPES from '../actions/types';
import { DEFAULT_UNTRACKED_USER_PARAMS } from '../assets/settings';

/**
 * UNTRACKED USERPARAMS reducer
 * @param {object} state
 * @param {object} action
 */
export default (state = DEFAULT_UNTRACKED_USER_PARAMS, { payload, type }) => {
    const {
        beadType,
        color,
        isChecked,
        isDisplayed,
        isHotkeyEnabled,
        style,
        zoomType
    } = payload ? payload : {};

    // Copy to avoid modifying state
    let stateCopy = Object.assign({}, state);

    switch(type){
        case TYPES.UPDATE_BEAD_STYLE_TYPE:
            stateCopy.beadStyle = style;
            break;
        
        case TYPES.APPLY_COLOR_TYPE:
            stateCopy.selectedColor = color;
            break;

        case TYPES.DISPLAY_COLOR_PICKER_TYPE:
            stateCopy.visibleSidebars = isChecked;
            break;
            
        case TYPES.DISPLAY_DEFAULT_COLOR_TYPE:
            stateCopy.visibleDefaultColor = isChecked;
            break;

        case TYPES.DISPLAY_GRID_HEADERS_TYPE:
            stateCopy.visibleGridHeaders = isChecked;
            break;

        case TYPES.DISPLAY_WAIT_ANIMATION_TYPE:
            stateCopy.visibleWaitAnimation = isDisplayed;
            break;

        case TYPES.ENABLE_HOTKEYS_TYPE:
            stateCopy = { ...stateCopy, isHotkeyEnabled };
            break;

        case TYPES.UPDATE_SELECTION_TYPE_TYPE:
            stateCopy.selectionType = beadType;
            break;
        
        case TYPES.ZOOM_CANVAS_TYPE:
            switch (zoomType) {
                case 'in':
                    if (stateCopy.canvasSize + DEFAULT_ZOOMING_STEP <= DEFAULT_CANVAS_SIZE.MAX) {
                        stateCopy.canvasSize += DEFAULT_ZOOMING_STEP;
                    }
                    break;
                case 'out':
                    if (stateCopy.canvasSize - DEFAULT_ZOOMING_STEP >= DEFAULT_CANVAS_SIZE.MIN) {
                        stateCopy.canvasSize -= DEFAULT_ZOOMING_STEP;
                    }
                    break;
                default:
                    break;
            }
            break;

        default:
            break;
    }
    return stateCopy;
};