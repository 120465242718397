import React from 'react';
import PropTypes from 'prop-types';
import { Image, Modal } from 'semantic-ui-react';

import '../_style/WaitingModal.css';

export const WaitModal = ({
    closeOnDimmerClick,
    closeOnEscape,
    open
}) => {
    const waitAnimationSrc = `${process.env.PUBLIC_URL}/img/white_bg_waiting.gif`;

    return (
        <div>
            <Modal
                className='waiting-modal'
                closeOnEscape={ closeOnEscape }
                closeOnDimmerClick={ closeOnDimmerClick }
                dimmer='blurring'
                open={ open }
            >
                <Modal.Header className='waiting-modal-header'>Please wait</Modal.Header>
                <Modal.Content className='waiting-modal-content'>
                    <Image 
                        centered
                        className='waiting-modal-image'
                        src={ waitAnimationSrc } 
                    />
                </Modal.Content>
            </Modal>
        </div>
    );
}

WaitModal.defaultProps = { 
    closeOnDimmerClick: false,
    closeOnEscape:      false,
    open:               false 
}

process.env.NODE_ENV !== 'production' ? WaitModal.propTypes = {
    closeOnDimmerClick: PropTypes.bool,
    closeOnEscape:      PropTypes.bool,
    open:               PropTypes.bool
} : void 0;

export default WaitModal;