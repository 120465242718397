import * as TYPES from '../actions/types';
import { DEFAULT_TRACKED_USER_PARAMS, HEADER_TYPES } from '../assets/settings';

/**
 * TRACKED USERPARAMS reducer
 * @param {object} state
 * @param {object} action
 */
export default (state = DEFAULT_TRACKED_USER_PARAMS, { payload, type }) => {
    const {
        gridSize,
        isChecked,
        number,
        position
    } = payload ? payload : {};
    const { headerType } = position ? position : {};

    // Copy to avoid modifying state
    let stateCopy = Object.assign({}, state);

    switch(type){
        // A header is selected
        case TYPES.SELECT_GRID_HEADER_TYPE:
            stateCopy.selectedHeaderPosition = 
                JSON.stringify(position) === JSON.stringify(state.selectedHeaderPosition)
                    ? {}
                    : position;
            break;

        // Update the grid size upon columns number update
        case TYPES.UPDATE_COLUMNS_TYPE:
            stateCopy.gridSize = {
                columns: number,
                rows: stateCopy.gridSize.rows
            };
            break;

        // Display grid headerss
        case TYPES.DISPLAY_GRID_HEADERS_TYPE:
            if (!isChecked) {
                stateCopy.selectedHeaderPosition = {};
            }
            break;

        // Grid size is set
        case TYPES.UPDATE_GRID_SIZE_TYPE:
            stateCopy.gridSize = gridSize;
            break;

        // Update the grid size upon line addition from a selected header
        case TYPES.ADD_LINE_TYPE:            
            switch  (headerType) {
                case HEADER_TYPES.COLUMN:
                    stateCopy.gridSize = {
                        columns: stateCopy.gridSize.columns + 1,
                        rows: stateCopy.gridSize.rows
                    };
                    break;
                case HEADER_TYPES.ROW:
                    stateCopy.gridSize = {
                        columns: stateCopy.gridSize.columns,
                        rows: stateCopy.gridSize.rows + 1
                    };
                    break;
                default:
                    break;
            }
            stateCopy.selectedHeaderPosition = {};
            break;

        // Update the grid size upon line deletion from a selected header
        case TYPES.DELETE_LINE_TYPE:
            switch  (headerType) {
                case HEADER_TYPES.COLUMN:
                    stateCopy.gridSize = {
                        columns: stateCopy.gridSize.columns - 1,
                        rows: stateCopy.gridSize.rows
                    };
                    break;
                case HEADER_TYPES.ROW:
                    stateCopy.gridSize = {
                        columns: stateCopy.gridSize.columns,
                        rows: stateCopy.gridSize.rows - 1
                    };
                    break;
                default:
                    break;
            }
            stateCopy.selectedHeaderPosition = {};
            break;

        // Update the grid size upon rows number update
        case TYPES.UPDATE_ROWS_TYPE:
            stateCopy.gridSize = {
                columns: stateCopy.gridSize.columns,
                rows: number
            };
            break;
        
        default:
            break;
    }
    return stateCopy;
};