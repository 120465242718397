import * as TYPES from '../actions/types';
import { MAX_UNDO_REDO_STACK } from '../assets/settings';

const ACTIONS_NOT_UNDO_REDO_TRACKED = [
    TYPES.UPDATE_BEAD_LIST_TYPE,
    TYPES.UPDATE_BEAD_STYLE_TYPE,
    TYPES.DISPLAY_GRID_HEADERS_TYPE,
    TYPES.DISPLAY_COLOR_PICKER_TYPE,
    TYPES.DISPLAY_DEFAULT_COLOR_TYPE,
    TYPES.DISPLAY_WAIT_ANIMATION_TYPE,
    TYPES.UPDATE_GRID_SIZE_TYPE,
    TYPES.UPDATE_SELECTION_TYPE_TYPE,
    TYPES.ZOOM_CANVAS_TYPE,
];

/**
 * Wrapper to add undo-redo capability to a reducer
 * @param {func} reducer
 */
export default reducer => {
    // Call the reducer with empty action to populate the initial state
    const initialState = {
        past: [],
        present: reducer(undefined, {}),
        future: []
    };

    // Return a reducer that handles undo and redo
    return (state = initialState, action) => {
        const { past, present, future } = state;

        // Actions not undo-redo tracked
        if (ACTIONS_NOT_UNDO_REDO_TRACKED.indexOf(action.type) !== -1) {
            return {
                past,
                present: reducer(present, action),
                future
            };
        }

        switch (action.type) {
            case TYPES.CLEAR_HISTORY_TYPE: {
                return {
                    past: [],
                    present: present,
                    future: []
                };
            }

            case TYPES.SAVE_CURRENT_STATE_TYPE: {
                const newPast = past.length >= MAX_UNDO_REDO_STACK
                    ? past.slice(1)
                    : [...past];

                return {
                    past: [...newPast, present],
                    present: present,
                    future: []
                };
            }

            case '@@redux-undo/UNDO': {
                const previous = past[past.length - 1];
                const newPast = past.slice(0, past.length - 1);
                return {
                    past: newPast,
                    present: previous,
                    future: [present, ...future]
                };
            }

            case '@@redux-undo/REDO': {
                const next = future[0];
                const newFuture = future.slice(1);
                return {
                    past: [...past, present],
                    present: next,
                    future: newFuture
                };
            }

            default:
                const newPast = past.length >= MAX_UNDO_REDO_STACK
                    ? past.slice(1)
                    : [...past];

                // Delegate handling the action to the passed reducer
                const newPresent = reducer(present, action);
                return {
                    past: [...newPast, present],
                    present: newPresent,
                    future: []
                };
        }
    }
}